@import '../../../../styles/customMediaQueries.css';


.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 497px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  & h1 {
    max-width: 100%;
    line-height: 1.2;
    font-weight: 900;
    color: var(--colorBlack);
    letter-spacing: 0;

    @media (--viewportLarge) {
      line-height: 56px;
    }
  }

  & h1 + p {
    font-size: 18px;
    line-height: 1.2;
    font-weight: var(--fontWeightBold);
    color: var(--colorBlack);
  }

  & [class*="SectionContainer_sectionContent"] {
    padding-bottom: 60px;
    
    @media (--viewportLarge) {
      padding-bottom: 125px;
    }
  }
}
