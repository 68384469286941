@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
  padding: 0 15px;
}

.footer {
  max-width: 1217px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 64px 15px 30px;
  
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
  display: flex;
    flex-direction: column;
    gap: 12px;
    @media (max-width: 767px){
      gap: 5px;
    }
    li{
      a{
        color: var(--colorGrey700);font-size: 14px;
        font-weight: 400;    
        display: inline;
        -webkit-hyphens: auto;
        hyphens: auto;
        word-break: break-all;
        word-break: break-word;
        transition: 0.3s ease all;
        position: relative;
        text-decoration: none;
        &:hover{
          color: var(--marketplaceColor); 
        }
        &:before{
          content: "";
          background: var(--marketplaceColor);
          position: absolute;
          left: auto;            top: 100%;
          right: 0;
          width: 0;
          height: 1px;
          transition: 0.3s ease all;
        }
        &:hover{
          &:before{
          left: 0;
          right: auto;
          width: 100%;
        }
      }
      } 
    }
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

/* .content {
  display: grid;
  justify-content: center;
  align-content: end;
  grid-auto-rows: auto;
  grid-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'details'
    'grid'
    'links';
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}
.copyRightBlock{
  text-align: center;
    padding-bottom: 32px;
}
.slogan {
  display: block;
  font-family: 'Sora';
  font-size: 15px;
  font-weight: 400;
  line-height: 32.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  max-width: 217px;
  color: #1B2124;
}
.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}
.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}
.logoWrapper {
  max-width: 370px;
}
.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 225px);
  }
}

@media (min-width: 350px) {
  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 { 
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol2 { 
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol3 { 
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }
  .contentCol4 { 
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
} */
.links{
       grid-template-columns: repeat(3, 1fr);    grid-gap: 32px;
  align-content: start;
  display: grid; 
  grid-auto-rows: auto;
  justify-content: center;
  @media (max-width: 767px){
    grid-template-columns:1fr;gap: 0px
  }
  h4{
    font-size: 16px;
    font-weight: 600;
    @media (max-width: 767px){ 
        line-height: normal;
        padding: 0; 
    }
  }
}
 .footerContent{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  @media (max-width: 767px){
    grid-template-columns: 1fr;
    gap: 20px;
  }
 }
 .brand{ 
  p{
    text-align: left;
    color: #1b2124;
    display: block;
    font-family: Sora;
    font-size: 15px;
    font-weight: 400;
    line-height: 32.4px;    
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    text-underline-position: from-font;
  }
  .socialMedia{
    display: grid;  
            grid-auto-columns: minmax(0, 1fr);
            grid-auto-flow: column;
            max-width: max-content;
            gap: 10px;
            margin-top: 10px;
            svg{
              path{fill: #1b2124;}
            }
  }
  a{
    color: #1b2124; 
    font-family: Sora;
    font-size: 15px;
    font-weight: 400; 
    text-align: left;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    text-underline-position: from-font;
  }
 }